@import '~antd/lib/style/index.less';
@import '~antd/lib/modal/style/index.less';
@import '~antd/lib/spin/style/index.less';
@import '~antd/lib/notification/style/index.less';

.ant-modal {
  pointer-events: auto;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}

@primary-color: #00A3E5;